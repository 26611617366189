<template>
  <v-row class="ma-0">
    <v-col
      :cols="filterGender ? 6 : 9"
      class="px-2 pb-0"
    >
      <v-text-field
        v-model="filter.search"
        prepend-inner-icon="fal fa-search"
        placeholder="Search"
        clearable
        dense
        outlined
        hide-details
        class="text-caption"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-search
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col
      v-if="filterGender"
      cols="3"
      class="px-2 pb-0"
    >
      <v-select
        v-model="filter.gender"
        :items="[
          { text: $t('male'), value: 'male' },
          { text: $t('female'), value: 'female' },
          { text: $t('unknown'), value: 'unknown' }
        ]"
        placeholder="Gender"
        multiple

        dense
        outlined
        class="text-caption mb-n6"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-people
          </v-icon>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="3"
      class="px-2 pb-0"
    >
      <v-select
        v-model="filter.pediatric"
        :items="[
          {
            'value': false,
            'text': 'Adult'
          },
          {
            'value': true,
            'text': 'Pediatric'
          },
        ]"
        placeholder="Pediatric"
        dense
        outlined
        clearable
        class="text-caption mb-n6"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-child
          </v-icon>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="3"
      class="px-2 pb-0"
    >
      <v-select
        v-model="filter.organisms"
        :items="filterOrganisms"
        :label="filter.organisms.length ? 'Pathogens' : null"
        placeholder="Pathogens"
        multiple
        dense
        outlined
        clearable
        class="text-caption mb-n6"
      >
        <template #prepend-inner>
          <v-icon

            small
            class="mt-1"
            color="grey"
          >
            fal fa-disease
          </v-icon>
        </template>
        <template #xselection="{ item }">
          <v-chip
            x-small
            class="mb-1"
          >
            {{ item | truncate(10) }}
          </v-chip>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="3"
      class="px-2 pb-0"
    >
      <v-select
        v-model="filter.resistances"
        :items="filterResistances"
        :label="filter.resistances.length ? 'Resistances' : null"
        placeholder="Resistances"
        multiple
        dense
        outlined
        clearable
        class="text-caption mb-n6"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-shield-virus
          </v-icon>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="3"
      class="px-2 pb-0"
    >
      <v-select
        v-model="filter.allergies"
        :items="filterAllergies"
        :label="filter.allergies.length ? 'Allergies' : null"
        placeholder="Allergies"
        multiple
        dense
        outlined
        clearable
        class="text-caption mb-n6"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-allergies
          </v-icon>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="3"
      class="px-2"
    >
      <v-select
        v-model="filter.diagnoses"
        :items="filterDiagnoses"
        :label="filter.diagnoses.length ? 'Diagnoses' : null"
        placeholder="Diagnoses"
        multiple
        dense
        outlined
        clearable
        class="text-caption mb-n6"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-diagnoses
          </v-icon>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    props: {
      filterPediatric: Boolean,
      filterGender: {
        type: [Boolean, String],
        default: false,
      },
      items: {
        type: Array,
        default: () => ([]),
      },
      allergies: {
        type: Array,
        default: () => ([]),
      },
    },
    data: () => ({
      loading: false,
      filter: {
        search: null,
        organisms: [],
        resistances: [],
        allergies: [],
        diagnoses: [],
        gender: [],
        pediatric: null,
      },
    }),
    computed: {
      filteredItems () {
        var items = this.items
        if (this.filter.search && items.length) {
          var term = this.filter.search.toLowerCase()
          if (items[0].instruction) {
            items = items.filter(item => item.instruction?.en?.toLowerCase().includes(term))
          } else if (items[0].impression_duration) {
            items = items.filter(item => {
              return (item.impression_duration && item.impression_duration.toLowerCase().includes(term)) ||
                (item.drugs && item.drugs.map(d => d.name).join(',').toLowerCase().includes(term)) ||
                (item.drugs && item.drugs.map(d => d.brand_names).join(',').includes(term))
            })
          }
        }
        if (this.filter.organisms.length && items.length) {
          items = items.filter(item => this.filter.organisms.every(pathogen => item.pathogens.includes(pathogen)))
        }
        if (this.filter.resistances.length && items.length) {
          items = items.filter(item => this.filter.resistances.every(resistance => item.resistance && item.resistance.includes(resistance)))
        }
        if (this.filter.allergies.length && items.length) {
          items = items.filter(item => this.filter.allergies.every(allergen => item.allergies.includes(allergen)))
        }
        if (this.filter.diagnoses.length && items.length) {
          items = items.filter(item => this.filter.diagnoses.every(diagnosis => item.diagnosis.includes(diagnosis)))
        }
        if (this.filter.gender.length && items.length) {
          items = items.filter(item => this.filter.gender.includes(item.gender))
        }
        if (this.filter.pediatric != null && items.length) {
          items = items.filter(item => item.pediatric == this.filter.pediatric)
        }
        return items.slice(0, 25)
      },
      filterOrganisms () {
        return this.items.length ? this.items.flatMap(item => item.pathogens).filter(n => n).sort() : [];
      },
      filterResistances () {
        return this.items.length ? [...new Set(this.items.flatMap(item => item.resistance))].filter(n => n).sort() : []
      },
      filterAllergies () {
        return this.items.length ? this.items.flatMap(item => item.allergies).filter(n => n).sort() : [];
      },
      filterDiagnoses () {
        return this.items.length ? this.items.flatMap(item => item.diagnosis).filter(n => n).sort() : [];
      },
    },
    watch: {
      items () {
        this.filter = {
          search: null,
          organisms: [],
          resistances: [],
          allergies: [],
          diagnoses: [],
          gender: [],
          pediatric: null,
        }
      },
      filter: {
        deep: true,
        handler () {
          this.$emit('filtered', this.filteredItems)
        },
      },
    },
  }
</script>
